* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.card {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

input, textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
}

button:hover {
  background-color: #0056b3;
}

.restart-button {
  background-color: #28a745;
}

.restart-button:hover {
  background-color: #218838;
}

h1 {
  margin-bottom: 20px;
}

h2 {
  margin: 20px 0;
}

h2.gold {
  font-size: 3em;
  color: gold;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
  font-size: 1.2em;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

label {
  display: block;
  text-align: left;
  margin: 10px 0;
}

label input, label textarea {
  display: block;
  margin-top: 5px;
}

/* Dynamic button colors */
.voter-button-1 {
  background-color: #007bff;
}

.voter-button-2 {
  background-color: #28a745;
}

.voter-button-3 {
  background-color: #ffc107;
}

.voter-button-4 {
  background-color: #dc3545;
}

.voter-button-5 {
  background-color: #6c757d;
}

.voter-button-6 {
  background-color: #17a2b8;
}

.voter-button-7 {
  background-color: #6610f2;
}

.voter-button-8 {
  background-color: #e83e8c;
}

.voter-button-9 {
  background-color: #fd7e14;
}

.voter-button-10 {
  background-color: #20c997;
}
